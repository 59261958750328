import React from 'react';

export default (expiresOnSeconds) => {
  const [timeRemaining, setTimeRemaining] = React.useState(null);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      const now = new Date().getTime() / 1000;
      setTimeRemaining(expiresOnSeconds - now);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [expiresOnSeconds, setTimeRemaining, timeRemaining]);

  return timeRemaining;
};
