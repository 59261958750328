import * as SharedStyles from '../Utils/SharedStyles';
import * as types from '../../types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import styled from '@emotion/styled';
import Typography from '@material-ui/core/Typography';

const Strong = styled.span`
  font-weight: bolder;
`;

const EntryHelp = ({ onClose, open }) => {
  if (!open) return null;

  return (
    <Dialog fullWidth={true} open={open} onClose={onClose}>
      <DialogTitle>Messaging Tips</DialogTitle>
      <DialogContent>
        <ul>
          <li>
            <Typography variant='body1'>
              To include linebreaks in your message, hold down either the <em>Shift</em>,{' '}
              <em>Command</em>, <em>Alt</em>, <em>Option</em>, or <em>Control</em> key when pressing{' '}
              <em>Enter</em> to create a line break instead of submitting your message.
            </Typography>
          </li>
          <li>
            <Typography variant='body1'>
              You can edit and delete messages that you've created. To do this, click the message
              and a menu will be shown.
            </Typography>
          </li>
          <li>
            <Typography variant='body1'>
              Markdown (github flavored) is supported, including lists and tables!.
            </Typography>
            <Typography variant='body1'>Some common usages:</Typography>
            <ul>
              <li>
                <Typography variant='body1'>
                  Use * to emphasize text: *text* => <em>text</em>.
                </Typography>
              </li>
              <li>
                <Typography variant='body1'>
                  Use ** to bold text: **text** => <Strong>text</Strong>.
                </Typography>
              </li>
              <li>
                <SharedStyles.Link
                  href='https://help.github.com/en/github/writing-on-github/basic-writing-and-formatting-syntax'
                  target='_blank'
                  rel='noopener'
                >
                  <Typography variant='body1'>Formatting details.</Typography>
                </SharedStyles.Link>{' '}
              </li>
            </ul>
          </li>
        </ul>
      </DialogContent>
      <DialogActions>
        <Button aria-label='Got it' onClick={onClose} variant='outlined'>
          Got It!
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EntryHelp.propTypes = {
  onClose: types.func.isRequired,
  open: types.bool.isRequired,
};

export default EntryHelp;
