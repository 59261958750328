import * as firebase from '../firebase';
import * as SharedStyles from './Utils/SharedStyles';
import * as types from '../types';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Loading from './Utils/Loading';
import MaterialTextField from './Utils/MaterialTextField';
import NotFound from './ChatRoom/NotFound';
import PageContainer from './Utils/PageContainer';
import React from 'react';
import SecurityDetails from './SecurityDetails';
import styled from '@emotion/styled';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

const Container = styled.div`
  margin: 2rem 0;
`;

const GoButton = withStyles(() => ({
  root: {
    margin: '0.5rem 0 0 1rem',
  },
}))(Button);

const Invite = ({ history, match }) => {
  const { inviteId } = match.params;
  const [errMsg, setErrMsg] = React.useState('');
  const [formUsername, setFormUsername] = React.useState('');
  const [invalidInvite, setInvalidInvite] = React.useState(inviteId.trim().length !== 6);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const onChangeUsername = (event) => setFormUsername(event.target.value);

  const onSave = async () => {
    const trimmedUsername = formUsername.trim();
    if (!isSubmitting && trimmedUsername !== '') {
      setErrMsg('');
      setIsSubmitting(true);

      const result = await firebase.acceptInviteRequest(
        inviteId.trim().toLowerCase(),
        trimmedUsername
      );

      setIsSubmitting(false);
      if (!result.success) {
        setErrorState(result.errMsg);
        return;
      }
      history.push(`/c/${result.chatId}/${result.userId}`);
    }
  };

  const setErrorState = (errMsg) => {
    if (errMsg === 'Invite has expired.') {
      setInvalidInvite(true);
    } else if (errMsg === 'Name is already in use in this chat.') {
      setErrMsg('The name you selected is already used in this chat, please try another.');
    } else {
      setErrMsg("Something went horribly wrong and we weren't able to process your request.");
    }
  };

  const renderForm = () => (
    <React.Fragment>
      <Typography variant='h6'>Enter your name to use in this chat </Typography>
      <Container>
        <MaterialTextField
          autoFocus={true}
          disabled={isSubmitting}
          inputProps={{ maxLength: 30 }}
          fullWidth={false}
          onChange={onChangeUsername}
          onSave={onSave}
          type='text'
          value={formUsername}
        />
        <GoButton
          aria-label='Go to chat room'
          color='primary'
          disabled={!formUsername.trim().length || isSubmitting}
          onClick={onSave}
          size='large'
          variant='contained'
        >
          <CheckCircleIcon />
        </GoButton>
      </Container>
      <Container>
        <Typography color='textSecondary' variant='body2'>
          By using this site, you assert that you are over eighteen years old and you agree to our{' '}
          <SharedStyles.Link href='/terms'>terms of service</SharedStyles.Link>.
        </Typography>
      </Container>
      {isSubmitting && <Loading text='' sizeInRem={4} />}
      {errMsg !== '' && (
        <Container>
          <Alert severity='error' variant='filled'>
            {errMsg}
          </Alert>
        </Container>
      )}
    </React.Fragment>
  );

  if (invalidInvite) {
    return <NotFound />;
  }

  return (
    <PageContainer>
      <SharedStyles.SpacedCenteredContainer>{renderForm()}</SharedStyles.SpacedCenteredContainer>
      <SecurityDetails />
    </PageContainer>
  );
};

Invite.propTypes = {
  history: types.object,
  match: types.InviteUrlParams.isRequired,
};

export default withRouter(Invite);
