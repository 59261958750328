import * as Constants from '../../constants';
import * as effects from '../../hooks/effects';
import * as types from '../../types';
import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
  font-family: 'Cutive Mono', monospace;
  font-size: 200%;
  height: 2.5rem;
  margin-top: 1rem;
  text-align: center;

  @media (max-width: ${Constants.MEDIA_QUERY.MD}px) {
    font-size: 150%;
    height: 2rem;
  }

  @media (max-width: ${Constants.MEDIA_QUERY.SM}px) {
    font-size: 110%;
  }
`;

const DeleteContainer = styled(Container)`
  background: ${(props) => props.theme.palette.error.dark};
  font-size: 150%;

  @media (max-width: ${Constants.MEDIA_QUERY.MD}px) {
    font-size: 110%;
  }

  @media (max-width: ${Constants.MEDIA_QUERY.SM}px) {
    font-size: 90%;
  }
`;

const Timer = ({ expiresOn }) => {
  const timeRemaining = effects.useTimer(expiresOn.seconds);

  if (timeRemaining === null) {
    return <Container></Container>;
  } else if (timeRemaining <= 0) {
    return <DeleteContainer>pending deletion </DeleteContainer>;
  }

  const hours = Math.floor(timeRemaining / 3600);
  const mins = Math.floor((timeRemaining - hours * 3600) / 60);
  const secs = Math.floor(timeRemaining - hours * 3600 - mins * 60);

  return (
    <Container title='Time remaining until this entire chat and all of its messages are automatically deleted'>
      {hours.toString().padStart(2, '0')}:{mins.toString().padStart(2, '0')}:
      {secs.toString().padStart(2, '0')}
    </Container>
  );
};

Timer.propTypes = {
  expiresOn: types.Timestamp,
};

export default Timer;
