import * as Constants from '../constants';
import * as firebase from '../firebase';
import * as types from '../types';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import React from 'react';
import MaterialTextField from './Utils/MaterialTextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import styled from '@emotion/styled';
import { withRouter } from 'react-router-dom';

const StyledFormControl = styled(FormControl)`
  margin-top: 1.5rem;
`;

const CreateChat = ({ history, onClose, open }) => {
  const [formUsername, setFormUsername] = React.useState('');
  const [formLifespan, setFormLifespan] = React.useState('5');
  const [isCreating, setIsCreating] = React.useState(false);
  const [showError, setShowError] = React.useState(false);

  if (!open) return null;

  const resetForm = () => {
    setFormUsername('');
    setIsCreating(false);
    setShowError(false);
  };

  const onChangeUsername = (event) => setFormUsername(event.target.value);

  const onChangeLifespan = (event) => setFormLifespan(event.target.value);

  const onCancel = () => {
    resetForm();
    onClose();
  };

  const onSave = async () => {
    const trimmedUsername = formUsername.trim();
    if (!isCreating && trimmedUsername !== '') {
      setIsCreating(true);
      const result = await firebase.createChatRequest(trimmedUsername, formLifespan);
      if (!result.success) {
        setShowError(true);
        setIsCreating(false);
        return;
      }
      resetForm();
      onClose();
      history.push(`/c/${result.chatId}/${result.userId}`);
    }
  };

  return (
    <Dialog fullWidth={true} open={open} onClose={onClose}>
      {showError && (
        <Alert severity='error' variant='filled'>
          Something went wrong and we couldn't create your chat.
        </Alert>
      )}
      <DialogTitle>Create a new chat</DialogTitle>
      <DialogContent>
        <MaterialTextField
          autoFocus={true}
          disabled={isCreating}
          inputProps={{ maxLength: 30 }}
          label='Enter your name for this chat'
          onChange={onChangeUsername}
          onSave={onSave}
          type='text'
          value={formUsername}
        />

        <StyledFormControl fullWidth={true}>
          <InputLabel id='lifespan-label'>Chat Lifespan</InputLabel>
          <Select labelId='lifespan-label' onChange={onChangeLifespan} value={formLifespan}>
            {Object.keys(Constants.LIFESPANS).map((opt) => (
              <MenuItem key={opt} value={opt}>
                {Constants.LIFESPANS[opt]}
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>
      </DialogContent>
      <DialogActions>
        {isCreating && <CircularProgress size={30} />}
        <Button aria-label='Cancel' disabled={isCreating} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          aria-label='Create Chat'
          color='primary'
          disabled={formUsername.trim() === '' || isCreating}
          onClick={onSave}
          variant='contained'
        >
          {isCreating ? 'Creating...' : 'Create Chat'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CreateChat.propTypes = {
  history: types.object,
  onClose: types.func,
  open: types.bool,
};

export default withRouter(CreateChat);
