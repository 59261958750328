import * as types from '../../types';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import EyeIcon from '@material-ui/icons/Visibility';
import EyeOffIcon from '@material-ui/icons/VisibilityOff';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import React from 'react';
import { LockContext } from './LockProvider';
import MaterialTextField from '../Utils/MaterialTextField';
import styled from '@emotion/styled';
import Typography from '@material-ui/core/Typography';

const PassRow = styled.div`
  align-items: flex-end;
  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: auto 3rem;
  justify-items: self-start;
  margin-bottom: 0.5rem;
`;

const StyledAlert = styled.div`
  height: 5rem;
  margin: 0;
  overflow: scroll;
`;

const StyledUl = styled.ul`
  margin-top: 0;
`;

const LockStatusDialog = ({ onClose }) => {
  const lockContext = React.useContext(LockContext);
  const {
    passPhrase,
    readEncrypted,
    saveEncrypted,
    setPassphrase,
    setSaveEncrypted,
    setReadEncrypted,
  } = lockContext;
  const [formPassPhrase, setFormPassPhrase] = React.useState(passPhrase);
  const [formSave, setFormSave] = React.useState(saveEncrypted);
  const [formRead, setFormRead] = React.useState(readEncrypted);
  const [showPassPhrase, setShowPassPhrase] = React.useState(false);

  const onSave = () => {
    setPassphrase(formPassPhrase);
    setReadEncrypted(formRead);
    setSaveEncrypted(formSave);
    onClose();
  };

  const hasPassPhrase = () => formPassPhrase.trim() !== '';

  const onChangePassPhrase = (event) => {
    const newPhrase = event.target.value;
    if (hasPassPhrase() && newPhrase.trim() === '') {
      //  Need to uncheck the checkboxes
      setFormRead(false);
      setFormSave(false);
    }
    setFormPassPhrase(newPhrase);
  };

  const onChangeRead = () => setFormRead(!formRead);

  const onChangeSend = () => {
    if (!formSave) {
      //  Since we are going to encrypt sent message, require read messages to
      //  be decrypted as well.
      setFormRead(true);
    }
    setFormSave(!formSave);
  };

  const onClickEye = () => setShowPassPhrase(!showPassPhrase);

  const renderEyeIcon = () => {
    const title = showPassPhrase
      ? 'Click to hide the pass phrase'
      : 'Click to show the pass phrase';
    const Icon = showPassPhrase ? EyeOffIcon : EyeIcon;
    return (
      <Button
        aria-label='Click to toggle display of pass phrase'
        onClick={onClickEye}
        size='large'
        variant='text'
        title={title}
      >
        <Icon />
      </Button>
    );
  };

  const renderInstructions = () => (
    <StyledAlert>
      <StyledUl>
        <li>
          <Typography variant='body1'>
            Encryption is an additional, <em>optional</em>, layer of security.
          </Typography>
        </li>
        <li>
          <Typography variant='body1'>
            If you enter a pass phrase, you can then enable encryption for messages you send and
            read. If messages are sent encrypted, only other people in the chat using the same pass
            phrase will be able to read your messages (including yourself).
          </Typography>
        </li>
        <li>
          <Typography variant='body1'>
            Likewise, if other users in this chat encrypt their messages, you will only be able to
            read them if you enter the same pass phrase and check the box to 'Decrypt read
            messages'.
          </Typography>
        </li>
        <li>
          <Typography variant='body1'>
            If a pass phrase is not used when sending messages, your messages will be readable by
            anyone in the room.
          </Typography>
        </li>
        <li>
          <Typography variant='body1'>
            Pass phrases are not stored on the server or in your browser. If you leave the chat room
            or refresh the page, you'll need to re-enter the pass phrase.
          </Typography>
        </li>
      </StyledUl>
    </StyledAlert>
  );

  return (
    <Dialog fullWidth={true} open={true} onClose={onClose}>
      <DialogTitle>Encryption Settings</DialogTitle>
      <DialogContent>
        <PassRow>
          <MaterialTextField
            autoFocus={true}
            label='Pass Phrase'
            onChange={onChangePassPhrase}
            onSave={onSave}
            type={showPassPhrase ? 'text' : 'password'}
            value={formPassPhrase}
          />
          {renderEyeIcon()}
        </PassRow>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                checked={formSave}
                disabled={formPassPhrase.trim() === ''}
                onClick={onChangeSend}
              />
            }
            label={`Encrypt sent messages${
              hasPassPhrase() ? '' : ' (enter pass phrase to enable)'
            }`}
          />
        </div>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                checked={formRead}
                disabled={formPassPhrase.trim() === '' || formSave}
                onClick={onChangeRead}
              />
            }
            label={`Decrypt read messages${
              hasPassPhrase() ? '' : ' (enter pass phrase to enable)'
            }`}
          />
        </div>
        {renderInstructions()}
      </DialogContent>
      <DialogActions>
        <Button aria-label='Cancel' onClick={onClose}>
          Cancel
        </Button>
        <Button aria-label='Save' color='primary' onClick={onSave} variant='contained'>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

LockStatusDialog.propTypes = {
  onClose: types.func.isRequired,
};

export default LockStatusDialog;
