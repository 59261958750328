import blueGrey from '@material-ui/core/colors/blueGrey';
import { createMuiTheme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import React from 'react';
import { ThemeProvider } from 'emotion-theming';

const darkTheme = createMuiTheme({
  palette: {
    primary: blueGrey,
    secondary: grey,
    type: 'dark',
  },
});

export default ({ children }) => <ThemeProvider theme={darkTheme}>{children}</ThemeProvider>;
