import * as firebase from '../../firebase';
import React from 'react';

export default (userId) => {
  const [userFound, setUserFound] = React.useState(false);
  const [userLoading, setUserLoading] = React.useState(true);
  const [username, setUsername] = React.useState('');

  React.useEffect(() => {
    const fetchData = async () => {
      const result = await firebase.getUser(userId);
      setUserLoading(false);
      if (result.exists) {
        setUsername(result.data().name);
        setUserFound(true);
      } else {
        setUserFound(false);
      }
    };
    fetchData();
  }, [userId]);

  return { userFound, userLoading, username };
};
