import AES from 'crypto-js/aes';
import enc from 'crypto-js/enc-utf8';

export const decrypt = (msg, key, handleFailure = true) => {
  if (!msg.encrypted) {
    return msg.message;
  }

  const result = key ? AES.decrypt(msg.message, key).toString(enc) : '';
  return result === '' && handleFailure ? '___unable to decrypt message___' : result;
};

export const encypt = (message, key) => AES.encrypt(message, key).toString();
