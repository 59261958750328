import * as Constants from '../constants';
import * as SharedStyles from './Utils/SharedStyles';
import * as types from '../types';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CreateChat from './CreateChat';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import styled from '@emotion/styled';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

const GetStartedContainer = styled(SharedStyles.CenteredContent)`
  align-items: center;
  display: grid;
  grid-column-gap: 5rem;
  grid-row-gap: 3rem;
  grid-template-columns: auto auto;
  justify-items: center;
  width: 80%;

  @media (max-width: ${Constants.MEDIA_QUERY.MD}px) {
    display: block;
  }
`;

const StyledPaper = styled(Paper)`
  align-items: flex-end;
  display: grid;
  height: 12rem;
  padding: 2rem;
  text-align: center;
  width: 20rem;

  @media (max-width: ${Constants.MEDIA_QUERY.MD}px) {
    margin: 0 auto 2rem auto;
  }
`;

const PaperHeader = styled(Typography)`
  align-self: flex-start;
`;

const FormElement = styled.div`
  justify-self: center;
  align-self: flex-end;
`;

const Helper = styled.div`
  grid-column: 1 / 3;
  justify-self: center;
  width: 40vw;

  @media (max-width: ${Constants.MEDIA_QUERY.MD}px) {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
`;

const StyledTextField = withStyles((theme) => ({
  root: {
    '& input': {
      background: theme.palette.secondary[800],
      fontSize: '125%',
      width: '7rem',
    },
    margin: '0 1rem 0 0',
  },
}))(TextField);

const GoButton = withStyles(() => ({
  root: {
    marginTop: '2px',
  },
}))(Button);

const GetStarted = ({ history }) => {
  const [showCreateModal, setShowCreateModal] = React.useState(false);
  const [code, setCode] = React.useState('');

  const onCodeKeyDown = (event) => {
    if (event.keyCode === 13) {
      goToInvite();
      event.preventDefault();
    }
  };

  const goToInvite = () => history.push(`/i/${code.trim().toLowerCase()}`);

  const onCreate = () => setShowCreateModal(true);

  return (
    <GetStartedContainer>
      <CreateChat onClose={() => setShowCreateModal(false)} open={showCreateModal} />
      <StyledPaper elevation={4}>
        <PaperHeader variant='h6'>Enter a code to join an existing chat</PaperHeader>
        <FormElement>
          <StyledTextField
            inputProps={{ maxLength: 7 }}
            margin='dense'
            onChange={(event) => setCode(event.target.value)}
            onKeyDown={onCodeKeyDown}
            placeholder='enter code'
            type='text'
            value={code}
            variant='outlined'
          />
          <GoButton
            aria-label='Go to Chat Room'
            color='primary'
            disabled={code.trim().length < 6}
            onClick={goToInvite}
            size='large'
            variant='contained'
          >
            <CheckCircleIcon />
          </GoButton>
        </FormElement>
      </StyledPaper>
      <StyledPaper elevation={4}>
        <PaperHeader variant='h6'>or, start a new chat</PaperHeader>
        <FormElement>
          <Button
            aria-label='Start new Chat'
            color='primary'
            onClick={onCreate}
            size='large'
            variant='contained'
          >
            Start new Chat
          </Button>
        </FormElement>
      </StyledPaper>
      <Helper>
        <Typography color='textSecondary' variant='body2'>
          By using this site, you assert that you are over eighteen years old and you agree to our{' '}
          <SharedStyles.Link href='/terms'>terms of service</SharedStyles.Link>.
        </Typography>
      </Helper>
    </GetStartedContainer>
  );
};

GetStarted.propTypes = {
  history: types.object,
};

export default withRouter(GetStarted);
