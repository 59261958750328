import { arrayOf, shape, string } from 'prop-types';
import ChatMessage from './chatMessage';
import Timestamp from './timestamp';

export default shape({
  params: shape({
    createdBy: string.isRequired,
    createdOn: Timestamp.isRequired,
    expiresOn: Timestamp.isRequired,
    inviteId: string.isRequired,
    messages: arrayOf(ChatMessage),
    users: arrayOf(string).isRequired,
  }),
});
