import debounce from 'lodash.debounce';
import React from 'react';

const CLOSE_TO_BOTTOM = 50;

export default () => {
  const [isScrolled, setIsScrolled] = React.useState(false);
  const [isInitialLoad, setIsInitialLoad] = React.useState(true);
  const elementRef = React.useRef(null);

  const onScroll = () => {
    const { clientHeight, scrollHeight, scrollTop } = elementRef.current;
    setIsScrolled(scrollHeight - clientHeight - scrollTop > CLOSE_TO_BOTTOM);
  };

  const onScrollDebounced = debounce(onScroll, 100, { maxWait: 1000 });

  const scrollToBottom = (el) => {
    const { clientHeight, scrollHeight } = el;
    el.scrollTop = scrollHeight - clientHeight;
  };

  React.useEffect(() => {
    if (elementRef && elementRef.current) {
      const el = elementRef.current;
      el.addEventListener('scroll', onScrollDebounced);

      if (isInitialLoad || !isScrolled) {
        scrollToBottom(el);
        if (isInitialLoad) {
          setIsInitialLoad(false);
        }
      }

      return () => {
        el.removeEventListener('scroll', onScrollDebounced);
      };
    }
  }, [elementRef, isInitialLoad, isScrolled, onScrollDebounced]);

  return elementRef;
};
