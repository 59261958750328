import { BrowserRouter, Switch, Route } from 'react-router-dom';
import debounce from 'lodash.debounce';
import EmotionThemeProvider from './Components/Utils/EmotionThemeProvider';
import Header from './Components/Header';
import Invite from './Components/Invite';
import Landing from './Components/Landing';
import React from 'react';
import Room from './Components/ChatRoom/Room';
import Terms from './Components/Terms';

//  Set vertical height to deal with mobile chrome and safari calculating with the address bar
const setVerticalHeight = () =>
  document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
setVerticalHeight();

//  And deal with various resizing events
const debouncedSetVerticalHeight = debounce(setVerticalHeight, 100, { maxWait: 1000 });
window.addEventListener('resize', debouncedSetVerticalHeight);
window.addEventListener('orientationchange', debouncedSetVerticalHeight);

export default () => {
  let content;
  content = (
    <BrowserRouter>
      <Switch>
        <Route path='/c/:chatId/:userId' component={Room} />
        <Route path='/i/:inviteId' component={Invite} />
        <Route path='/terms' component={Terms} />
        <Route path='/' component={Landing} />
      </Switch>
    </BrowserRouter>
  );
  // }

  return (
    <EmotionThemeProvider>
      <Header />
      {content}
    </EmotionThemeProvider>
  );
};
