import * as encryption from '../utils/encryption';
import firebase from 'firebase/app';
import 'firebase/firestore';

export default async (chatId, message, userId, passPhrase) => {
  try {
    const db = firebase.firestore();
    const msg = passPhrase ? encryption.encypt(message.trim(), passPhrase) : message.trim();

    //  Put the request into the database
    const createResp = await db.collection('createChatMessageRequest').add({
      chatId,
      createdOn: firebase.firestore.FieldValue.serverTimestamp(),
      encrypted: !!passPhrase,
      message: msg,
      userId,
    });
    const createRespId = createResp.id;

    //  Now we wait for the function to pick up the job and give us a response.
    const promise = new Promise((resolve, reject) => {
      const unsubscribe = db
        .collection('createChatMessageRequest')
        .doc(createRespId)
        .onSnapshot((snapshot) => {
          const data = snapshot.data();
          if (data.hasOwnProperty('success')) {
            unsubscribe();
            resolve({ success: !!data.success });
          }
        });
    });

    const result = await promise;

    //  Need to delete the entry now
    db.collection('createChatMessageRequest').doc(createRespId).delete();

    return result;
  } catch (e) {
    return { success: false };
  }
};
