import * as encryption from '../../utils/encryption';
import * as firebase from '../../firebase';
import * as types from '../../types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MaterialTextField from '../Utils/MaterialTextField';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import Alert from '@material-ui/lab/Alert';
import { LockContext } from './LockProvider';

const MessageCommands = ({ chatId, msg, onClose, triggerEvent, userId }) => {
  const [canNotDecrypt, setCanNotDecrypt] = React.useState(false);
  const [formMessage, setFormMessage] = React.useState('');
  const [isInError, setIsInError] = React.useState(false);
  const [isSaveInProgress, setIsSaveInProgress] = React.useState(false);
  const [showEditor, setShowEditor] = React.useState(false);

  const lockContext = React.useContext(LockContext);
  const { passPhrase, readEncrypted, saveEncrypted } = lockContext;

  const onDelete = () => {
    firebase.deleteChatMessageRequest(chatId, msg.id, userId);
    onClose();
  };

  const onStartEdit = (event) => {
    const messageText = encryption.decrypt(msg, readEncrypted && passPhrase, false);
    if (messageText === '') {
      setCanNotDecrypt(true);
    }

    setFormMessage(messageText);
    setShowEditor(true);

    //  Needed to keep the menu open and this component shown.
    event.preventDefault();
    event.stopPropagation();
  };

  const onCompleteEdit = async () => {
    if (!isSaveInProgress && formMessage.trim() !== '' && !canNotDecrypt) {
      setIsSaveInProgress(true);
      const result = await firebase.editChatMessageRequest(
        chatId,
        formMessage.trim(),
        msg.id,
        userId,
        saveEncrypted && passPhrase
      );

      setIsSaveInProgress(false);
      if (!result.success) {
        setIsInError(true);
      } else {
        onClose();
      }
    }
  };

  const renderEditor = () => (
    <Dialog disableBackdropClick={true} fullWidth={true} open={showEditor} onClose={onClose}>
      <DialogTitle>Edit Message</DialogTitle>
      {isInError && (
        <Alert severity='error' variant='filled'>
          Something went wrong and we couldn't edit your message.
        </Alert>
      )}
      {canNotDecrypt && (
        <Alert severity='error' variant='filled'>
          You can't edit this message since it can't be decrypted using the current pass phrase.
        </Alert>
      )}
      <DialogContent>
        <MaterialTextField
          autoFocus={true}
          disabled={isSaveInProgress}
          multiline={true}
          onChange={(event) => setFormMessage(event.target.value)}
          onSave={onCompleteEdit}
          rows={5}
          rowsMax={5}
          value={formMessage}
          variant='outlined'
        />
      </DialogContent>
      <DialogActions>
        {isSaveInProgress && <CircularProgress size={30} />}
        <Button aria-label='Cancel' disabled={isSaveInProgress} onClick={onClose}>
          Cancel
        </Button>
        <Button
          aria-label='Edit Message'
          color='primary'
          disabled={formMessage.trim() === '' || isSaveInProgress || canNotDecrypt}
          onClick={onCompleteEdit}
          variant='contained'
        >
          {isSaveInProgress ? 'Saving...' : 'Edit Message'}
        </Button>
      </DialogActions>
    </Dialog>
  );

  if (!triggerEvent || !triggerEvent.currentTarget) {
    return null;
  }

  return (
    <React.Fragment>
      {renderEditor()}
      <Menu
        anchorEl={triggerEvent.currentTarget}
        anchorPosition={{ left: triggerEvent.clientX, top: triggerEvent.clientY }}
        anchorReference='anchorPosition'
        onClose={onClose}
        open={true}
      >
        <MenuItem key={0} onClick={onStartEdit}>
          Edit Message...
        </MenuItem>
        <MenuItem key={1} onClick={onDelete}>
          Delete Message
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

MessageCommands.propTypes = {
  chatId: types.string.isRequired,
  msg: types.ChatMessage.isRequired,
  onClose: types.func,
  triggerEvent: types.shape({
    currentTarget: types.object,
    clientX: types.number,
    clientY: types.number,
  }),
  userId: types.string.isRequired,
};

export default MessageCommands;
