import * as Constants from '../../constants';
import React from 'react';
import styled from '@emotion/styled';

const PageContainer = styled.div`
  padding: 1rem 2rem 0 2rem;

  @media (max-width: ${Constants.MEDIA_QUERY.MD}px) {
    padding: 0.75rem 1.5rem;
  }

  @media (max-width: ${Constants.MEDIA_QUERY.SM}px) {
    padding: 0.5rem 1rem;
  }
`;

export default ({ children }) => <PageContainer>{children}</PageContainer>;
