import * as Constants from '../../constants';
import * as effects from '../../hooks/effects';
import * as encryption from '../../utils/encryption';
import * as types from '../../types';
import Empty from './Empty';
import MessageCommands from './MessageCommands';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from '@emotion/styled';
import Typography from '@material-ui/core/Typography';
import { LockContext } from './LockProvider';

const MessagesContainer = styled.div`
  margin: 0.5rem 1rem 0 2rem;
  overflow: scroll;

  @media (max-width: ${Constants.MEDIA_QUERY.MD}px) {
    margin: 0.5rem 1rem 0 1.5rem;
  }

  @media (max-width: ${Constants.MEDIA_QUERY.SM}px) {
    margin: 0.5rem 1rem 0 1rem;
  }
`;

const Header = styled.div``;

const Content = styled(ReactMarkdown)`
  white-space: pre-wrap;

  blockquote,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  pre,
  ul {
    margin-bottom: 0;
    margin-top: 0;
  }

  a {
    color: ${(props) => props.theme.palette.primary['100']};
  }

  code {
    font-size: 125%;
  }
`;

const MessageContainer = styled.div`
  padding: 0.1rem 0.5rem;
  margin-bottom: 0.5rem;

  :hover,
  &.clickedRow {
    background: ${(props) => props.theme.palette.secondary['800']};
  }

  &.ownsMessage:hover {
    cursor: pointer;
  }

  &.encrypted::before {
    content: '🔒';
    left: 1rem;
    position: absolute;

    @media (max-width: ${Constants.MEDIA_QUERY.MD}px) {
      left: 0.5rem;
    }

    @media (max-width: ${Constants.MEDIA_QUERY.SM}px) {
      left: 0.25rem;
    }
  }
`;

const Name = styled(Typography)`
  font-weight: 800;
  margin-right: 1rem;
`;

const Messages = ({ chatId, chatData, userId, username }) => {
  const elementRef = effects.useAutoScroller();
  const [showMenuId, setShowMenuId] = React.useState(null);
  const [commandsEvent, setCommandsEvent] = React.useState(null);

  const lockContext = React.useContext(LockContext);
  const { passPhrase, readEncrypted } = lockContext;

  const { messages } = chatData;
  if (!messages || !messages.length) {
    return <Empty />;
  }

  const renderTime = (time) => {
    const date = new Date(time.seconds * 1000);
    return date.toLocaleTimeString();
  };

  const renderMessageHeader = (msg) => (
    <Header>
      <Name display='inline' variant='body1'>
        {msg.createdBy}
      </Name>
      <Typography display='inline' variant='caption'>
        {renderTime(msg.createdOn)}
      </Typography>
    </Header>
  );

  const canEdit = (msg) => username && msg.createdBy === username && !msg.optimistic;

  const onRowClick = (msg, event) => {
    if (!canEdit(msg)) {
      return;
    }

    if (showMenuId !== msg.id) {
      setShowMenuId(msg.id);
      const { clientX, clientY, currentTarget } = event;
      setCommandsEvent({ clientX, clientY, currentTarget });
    }
  };

  const onMenuClose = () => {
    setShowMenuId(null);
    setCommandsEvent(null);
  };

  const getClassesForMsg = (msg) => {
    let result = showMenuId === msg.id ? 'clickedRow' : '';
    result += msg.encrypted ? ' encrypted ' : '';
    return result + (canEdit(msg) ? ' ownsMessage' : '');
  };

  let previousMessageUser;
  let previousMessageSeconds;

  return (
    <MessagesContainer ref={elementRef}>
      {messages.map((msg) => {
        let showHeader = true;
        if (msg.createdBy !== previousMessageUser) {
          previousMessageUser = msg.createdBy;
        } else if (msg.createdOn.seconds - previousMessageSeconds < 300) {
          showHeader = false;
        }
        previousMessageSeconds = msg.createdOn.seconds;

        return (
          <MessageContainer
            className={getClassesForMsg(msg)}
            key={msg.id}
            onClick={(event) => onRowClick(msg, event)}
            title={renderTime(msg.createdOn)}
          >
            {showHeader && renderMessageHeader(msg)}
            <Content>{encryption.decrypt(msg, readEncrypted && passPhrase)}</Content>
            {showMenuId === msg.id && (
              <MessageCommands
                chatId={chatId}
                msg={msg}
                onClose={onMenuClose}
                triggerEvent={commandsEvent}
                userId={userId}
              />
            )}
          </MessageContainer>
        );
      })}
    </MessagesContainer>
  );
};

Messages.propTypes = {
  chatData: types.ChatData,
  chatId: types.string.isRequired,
  userId: types.string.isRequired,
  username: types.string,
};

export default Messages;
