import firebase from 'firebase/app';
import 'firebase/firestore';

export default async (username, lifespan) => {
  try {
    const db = firebase.firestore();

    //  Put the request into the database
    const createResp = await db.collection('createChatRequest').add({
      createdOn: firebase.firestore.FieldValue.serverTimestamp(),
      lifespan,
      username,
    });
    const createRespId = createResp.id;

    //  Now we wait for the function to pick up the job and give us a response.
    const promise = new Promise((resolve, reject) => {
      const unsubscribe = db
        .collection('createChatRequest')
        .doc(createRespId)
        .onSnapshot((snapshot) => {
          const data = snapshot.data();
          if (data.hasOwnProperty('success')) {
            //  Stop listening to changes
            unsubscribe();

            //  Figure out if it worked or not
            if (data.success) {
              resolve({ chatId: data.chatId, success: true, userId: data.userId });
            } else {
              resolve({ success: false });
            }
          }
        });
    });

    const result = await promise;

    //  Need to delete the entry now
    db.collection('createChatRequest').doc(createRespId).delete();

    return result;
  } catch (e) {
    return { success: false };
  }
};
