import { bool, shape, string } from 'prop-types';
import Timestamp from './timestamp';

export default shape({
  createdBy: string.isRequired,
  createdOn: Timestamp.isRequired,
  encrypted: bool,
  id: string.isRequired,
  message: string.isRequired,
  optimistic: bool,
});
