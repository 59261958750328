import * as types from '../../types';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

const StyledTextField = withStyles((theme) => ({
  root: {
    '& input': {
      fontSize: '150%',
    },
    '& label.Mui-focused': {
      color: theme.palette.primary[100],
      fontSize: '150%',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.primary[100],
    },
  },
}))(TextField);

const MaterialTextField = (props) => {
  const onKeyDown = (event) => {
    //  Check for usage of the Enter key
    if (event.keyCode === 13 && props.onSave) {
      //  Ignore various types of Enter if this is a multi-line text field
      if (props.multiline && (event.shiftKey || event.crtlKey || event.altKey || event.metaKey)) {
        return;
      }

      props.onSave();
      event.preventDefault();
    }

    //  If they press Escape, clear out the entry
    if (event.keyCode === 27 && props.onChange) {
      props.onChange({ target: { value: '' } });
    }
  };

  const ignoredKeys = ['onSave'];
  const passedProps = Object.keys(props).reduce((acc, key) => {
    if (!ignoredKeys.includes(key)) {
      acc[key] = props[key];
    }
    return acc;
  }, {});

  return <StyledTextField fullWidth={true} margin='dense' onKeyDown={onKeyDown} {...passedProps} />;
};

MaterialTextField.propTypes = {
  onSave: types.func,
};

export default MaterialTextField;
