import * as types from '../../types';
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from '@emotion/styled';
import Typography from '@material-ui/core/Typography';

const LoadingContainer = styled.div`
  margin: 3rem auto;
  text-align: center;
`;

const LoadingTextContainer = styled(Typography)`
  margin-top: 1rem;
`;

const Loading = ({ sizeInRem, text }) => {
  return (
    <LoadingContainer>
      <CircularProgress size={`${sizeInRem}rem`} />
      <LoadingTextContainer variant='h5'>{text}</LoadingTextContainer>
    </LoadingContainer>
  );
};

Loading.propTypes = {
  sizeInRem: types.number,
  text: types.string,
};

Loading.defaultProps = {
  sizeInRem: 8,
  text: 'Loading...',
};

export default Loading;
