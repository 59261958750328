import * as Constants from '../constants';
import * as SharedStyles from './Utils/SharedStyles';
import GetStarted from './GetStarted';
import PageContainer from './Utils/PageContainer';
import React from 'react';
import SecurityDetails from './SecurityDetails';
import styled from '@emotion/styled';
import Typography from '@material-ui/core/Typography';

const Landing = styled.div`
  margin-top: 4rem;

  @media (max-width: ${Constants.MEDIA_QUERY.MD}px) {
    margin-top: 2rem;
  }

  @media (max-width: ${Constants.MEDIA_QUERY.SM}px) {
    margin-top: 1rem;
  }
`;

const GetStartedContainer = styled.div`
  min-height: max(50vh, 20rem);
`;

const DetailContainer = styled.div`
  background: ${(props) => props.theme.palette.secondary['800']};
  border-top: 1px solid ${(props) => props.theme.palette.secondary['700']};
  margin: 2rem -2rem 0 -2rem;
  padding: 2rem 2rem 4rem 2rem;

  @media (max-width: ${Constants.MEDIA_QUERY.MD}px) {
    margin: 1.5rem -1.5rem 0 -1.5rem;
    padding: 1.5rem;
  }

  @media (max-width: ${Constants.MEDIA_QUERY.SM}px) {
    margin: 1rem -1rem 0 -1rem;
    padding: 1rem;
  }
`;

const DetailHeader = styled(SharedStyles.CenteredContent)`
  text-align: center;

  img {
    vertical-align: text-bottom;
  }
`;

const DetailContent = styled(SharedStyles.CenteredContent)`
  margin-top: 2rem;
`;

export default () => (
  <PageContainer>
    <Landing>
      <GetStartedContainer>
        <GetStarted />
      </GetStartedContainer>
      <DetailContainer>
        <DetailHeader>
          <img alt='53cr3t' src='/images/logoText.png' />
          <Typography variant='h4'>self-destructing group chat</Typography>
        </DetailHeader>
        <DetailContent>
          <ul>
            <li>
              <Typography variant='body1'>
                Create chat rooms and invite as many people as you like into them.
              </Typography>
            </li>
            <li>
              <Typography variant='body1'>Chat rooms are text only.</Typography>
            </li>
            <li>
              <Typography variant='body1'>
                24-hours after creation (or shorter if you want), the entire chat room, all it's
                messages, and the associated users are all deleted from the server.
              </Typography>
            </li>
            <li>
              <Typography variant='body1'>
                The safest and most reliable way to communicate privately (details below).
              </Typography>
            </li>
            <li>
              <SharedStyles.Link href='/terms'>
                <Typography variant='body1'>Terms of Service.</Typography>
              </SharedStyles.Link>
            </li>
          </ul>
        </DetailContent>
      </DetailContainer>
      <SecurityDetails />
    </Landing>
  </PageContainer>
);
