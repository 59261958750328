import * as Constants from '../constants';
import React from 'react';
import styled from '@emotion/styled';

const HeaderContainer = styled.div`
  align-items: flex-start;
  background: ${(props) => props.theme.palette.secondary['800']};
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary['700']};
  display: grid;
  grid-column-gap: 2rem;
  grid-template-columns: 100px auto;
  padding: 0.25rem 2rem 0 2rem;

  @media (max-width: ${Constants.MEDIA_QUERY.MD}px) {
    grid-column-gap: 1.5rem;
    grid-template-columns: 75px auto;
    padding: 0.25rem 1.5rem 0 1.5rem;
  }

  @media (max-width: ${Constants.MEDIA_QUERY.SM}px) {
    grid-column-gap: 1rem;
    grid-template-columns: 50px auto;
    padding: 0.25rem 1rem 0 1rem;
  }
`;

const Logo = styled.div`
  img {
    height: 32px;
    width: 100px;

    @media (max-width: ${Constants.MEDIA_QUERY.MD}px) {
      height: 24px;
      width: 75px;
    }

    @media (max-width: ${Constants.MEDIA_QUERY.SM}px) {
      height: 16px;
      width: 50px;
    }
  }
`;

const LogoText = styled.div`
  img {
    height: 33px;
    width: 190px;

    @media (max-width: ${Constants.MEDIA_QUERY.MD}px) {
      height: 25px;
      width: 143px;
    }

    @media (max-width: ${Constants.MEDIA_QUERY.SM}px) {
      height: 17px;
      width: 95px;
    }
  }
`;

export default () => (
  <a href='/'>
    <HeaderContainer>
      <Logo>
        <img alt='logo' src='/images/logo.png' />
      </Logo>
      <LogoText>
        <img alt='53cr3t' src='/images/logoText.png' height='33' width='190' />
      </LogoText>
    </HeaderContainer>
  </a>
);
