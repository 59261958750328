import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import App from './App';
import blueGrey from '@material-ui/core/colors/blueGrey';
import CssBaseline from '@material-ui/core/CssBaseline';
import firebase from 'firebase/app';
import grey from '@material-ui/core/colors/grey';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

firebase.initializeApp({
  apiKey: 'AIzaSyDMvx0pMkEcSVxtbx_xtEWlncHU9sOyFX4',
  databaseURL: 'https://cr3t-29e86.firebaseio.com',
  projectId: 'cr3t-29e86',
  appId: '1:757341053037:web:71d135068b5d21a4e99f64',
});

const darkTheme = createMuiTheme({
  palette: {
    primary: blueGrey,
    secondary: grey,
    type: 'dark',
  },
});

ReactDOM.render(
  <ThemeProvider theme={darkTheme}>
    <CssBaseline />
    <App />
  </ThemeProvider>,
  document.getElementById('root')
);

serviceWorker.register();
