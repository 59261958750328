import * as ShardStyles from '../Utils/SharedStyles';
import React from 'react';
import styled from '@emotion/styled';
import Typography from '@material-ui/core/Typography';

const Container = styled(ShardStyles.SpacedCenteredContainer)`
  text-align: center;
`;

const ActionsHeader = styled.div`
  margin-top: 2rem;
  text-align: left;
`;

const ActionsContent = styled.div`
  margin-top: 1rem;
  text-align: left;
`;

const Strong = styled.span`
  font-weight: bolder;
`;

export default () => (
  <Container>
    <Typography variant='h4'>It looks kinda empty in here</Typography>
    <ActionsHeader>
      <Typography variant='h6'>You can:</Typography>
    </ActionsHeader>
    <ActionsContent>
      <ul>
        <li>
          <Typography variant='body1'>
            Invite others here using the <Strong>Add People</Strong> button on the right
          </Typography>{' '}
        </li>
        <li>
          <Typography variant='body1'>or, start things off by entering a message below.</Typography>{' '}
        </li>
      </ul>
    </ActionsContent>
  </Container>
);
