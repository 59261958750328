import * as SharedStyles from './Utils/SharedStyles';
import PageContainer from './Utils/PageContainer';
import React from 'react';
import styled from '@emotion/styled';
import Typography from '@material-ui/core/Typography';

const T = styled(Typography)`
  margin-bottom: 1rem;
`;

export default () => (
  <PageContainer>
    <SharedStyles.CenteredContent>
      <T variant='h4'>This policy is effective as a March 24, 2020.</T>
      <T variant='body1'>
        This Terms of Service ("Terms") applies to your access to and use of the websites, mobile
        apps, widgets, and other online products and services (collectively, the "Services")
        provided by 53cr3t ("53cr3t," "we," or "us").
      </T>
      <T variant='body1'>
        53cr3t is for fun and is intended to be a place for your entertainment, but we still need
        some basic rules. By accessing or using our Services, you agree to be bound by these Terms.
        If you do not agree to these Terms, you may not access or use our Services.
      </T>
      <T variant='h4'>Your Access to the Services</T>
      <T variant='body1'>
        Persons under the age of 18 are not allowed to create an account or otherwise use the
        Services. Additionally, if you are in the European Economic Area, you must be over the age
        required by the laws of your country to create an account or otherwise use the Services, or
        we need to have received verifiable consent from your parent or legal guardian.
      </T>
      <T variant='body1'>
        If you are accepting these Terms on behalf of another legal entity, including a business or
        a government, you represent that you have full legal authority to bind such entity to these
        terms.
      </T>

      <T variant='h4'>Your Use of the Services</T>
      <T variant='body1'>
        53cr3t grants you a personal, non-transferable, non-exclusive, revocable, limited license to
        use and access the Services solely as permitted by these Terms. We reserve all rights not
        expressly granted to you by these Terms.
      </T>
      <T variant='body1'>
        Except as permitted through the Services or as otherwise permitted by us in writing, your
        license does not include the right to:
      </T>
      <ul>
        <li>
          License, sell, transfer, assign, distribute, host, or otherwise commercially exploit the
          Services or Content.
        </li>
        <li>
          Modify, prepare derivative works of, disassemble, decompile, or reverse engineer any part
          of the Services or Content.
        </li>
        <li>
          Access the Services or Content in order to build a similar or competitive website,
          product, or service.
        </li>
      </ul>
      <T variant='body1'>
        We reserve the right to modify, suspend, or discontinue the Services (in whole or in part)
        at any time, with or without notice to you. Any future release, update, or other addition to
        functionality of the Services will be subject to these Terms, which may be updated from time
        to time. You agree that we will not be liable to you or to any third party for any
        modification, suspension, or discontinuation of the Services or any part thereof.
      </T>

      <T variant='h4'>Your Privacy</T>
      <T variant='body1'>We value your privacy. A lot. Likely more than you do. We:</T>
      <ul>
        <li>Ask for no personally identifiable information.</li>
        <li>
          Delete all user-entered information from servers and databases within 24-hours of it being
          entered (within a 5 minute window).
        </li>
        <li>Set no cookies, and make use of no local storage on your device.</li>
        <li>
          Do not make use of third-party analytics, advertisement, linking, or tracking systems.
        </li>
        <li>Do not collect location or device information.</li>
        <li>Do not share any information with third party systems.</li>
        <li>Maintain no server logs or database backups.</li>
      </ul>
      <T variant='body1'>
        We do make use of Google's Firebase system as our database backend and for our hosting
        services. Our Firebase system is configured to perform no tracking and no analytics. Despite
        that, we do notice that their system will occasionally write cookies. We suggest you read
        the{' '}
        <SharedStyles.Link href='https://firebase.google.com/support/privacy'>
          Firebase Privacy Policy
        </SharedStyles.Link>
        . For the purposes of their privacy policy, we make use of the Cloud Firestore and Firebase
        Hosting services.
      </T>

      <T variant='h4'>Your Content</T>
      <T variant='body1'>
        The Services may contain information, text, links, graphics, photos, videos, or other
        materials (“Content”), including Content created with or submitted to the Services by you or
        through your Account (“Your Content”). We take no responsibility for and we do not expressly
        or implicitly endorse any of Your Content.
      </T>
      <T variant='body1'>
        Please note that all content has a maximum lifespan of twenty-fours. After this time span,
        your content will be automatically and permanently deleted from our system. No backups are
        kept, delted content is irretrievable.
      </T>
      <T variant='body1'>
        By submitting Your Content to the Services, you represent and warrant that you have all
        rights, power, and authority necessary to grant the rights to Your Content contained within
        these Terms. Because you alone are responsible for Your Content, you may expose yourself to
        liability if you post or share Content without all necessary rights.
      </T>
      <T variant='body1'>
        You retain any ownership rights you have in Your Content, but you grant 53cr3t the following
        license to use that Content: Any ideas, suggestions, and feedback about 53cr3t or our
        Services that you provide to us are entirely voluntary, and you agree that 53cr3t may use
        such ideas, suggestions, and feedback without compensation or obligation to you.
      </T>
      <T variant='body1'>
        Although we have no obligation to screen, edit, or monitor Your Content, we may, in our sole
        discretion, delete or remove Your Content at any time and for any reason, including for a
        violation of these Terms, a violation of our Content Policy, or if you otherwise create
        liability for us.
      </T>

      <T variant='h4'>Content Policy</T>
      <T variant='body1'>
        53cr3t is a platform to discuss, connect, and share in a private environment. The nature of
        this content might be funny, serious, offensive, or anywhere in between. While
        participating, it’s important to keep in mind this value above all others: show enough
        respect to others so that we all may continue to enjoy 53cr3t for what it is.
      </T>
      <T variant='h6'>Unwelcome content</T>
      <T variant='body1'>
        While 53cr3t generally provides a lot of leeway in what content is acceptable, here are some
        guidelines for content that is not. Please keep in mind the spirit in which these were
        written, and know that looking for loopholes is a waste of time.
      </T>
      <T variant='body1'>Content is prohibited if it:</T>
      <ul>
        <li>Is illegal.</li>
        <li>Is involuntary pornography.</li>
        <li>Is sexual or suggestive content involving minors.</li>
        <li>It involves minors in any way.</li>
        <li>Encourages or incites violence.</li>
        <li>Threatens, harasses, or bullies or encourages others to do so.</li>
        <li>It contains personal and confidential information.</li>
        <li>Impersonates an individual or entity in a misleading or deceptive manner.</li>
        <li>
          Uses 53cr3t to solicit or facilitate any transaction or gift involving certain goods and
          services.
        </li>
        <li>Is spam.</li>
      </ul>
      <T variant='h6'>Prohibited behavior</T>
      <T variant='body1'>
        In addition to not submitting unwelcome content, the following behaviors are prohibited on
        53cr3t:
      </T>
      <ul>
        <li>Breaking 53cr3t or doing anything that interferes with normal use of 53cr3t.</li>
      </ul>

      <T variant='h4'>Things You Cannot Do</T>
      <T variant='body1'>
        When accessing or using the Services, you must respect others and their rights, including by
        following these Terms and the Content Policy, so that we all may continue to use and enjoy
        the Services. We support the responsible reporting of security vulnerabilities. To report a
        security issue, please send an email to security@53cr3t.com.
      </T>
      <T variant='body1'>When accessing or using our Services, you will not:</T>
      <ul>
        <li>
          Create or submit Content that violates our Content Policy or attempt to circumvent any
          content-filtering techniques we use.
        </li>
        <li>
          Use the Services to violate applicable law or infringe any person or entity's intellectual
          property or any other proprietary rights.
        </li>
        <li>
          Attempt to gain unauthorized access to another user’s Account or to the Services (or to
          other computer systems or networks connected to or used together with the Services).
        </li>
        <li>
          Upload, transmit, or distribute to or through the Services any computer viruses, worms, or
          other software intended to interfere with the intended operation of a computer system or
          data.
        </li>
        <li>
          Use the Services to harvest, collect, gather or assemble information or data regarding the
          Services or users of the Services except as permitted in these Terms or in a separate
          agreement with 53cr3t.
        </li>
        <li>
          Use the Services in any manner that could interfere with, disrupt, negatively affect, or
          inhibit other users from fully enjoying the Services or that could damage, disable,
          overburden, or impair the functioning of the Services in any manner.
        </li>
        <li>
          Intentionally negate any user's actions to delete or edit their Content on the Services.
        </li>
        <li>
          Access, query, or search the Services with any automated system, other than through our
          published interfaces and pursuant to their applicable terms.
        </li>
      </ul>

      <T variant='h4'>Copyright, the DMCA & Takedowns</T>
      <T variant='body1'>
        53cr3t respects the intellectual property of others and requires that users of our Services
        do the same. We have a policy that includes the removal of any infringing materials from the
        Services and for the termination, in appropriate circumstances, of users of our Services who
        are repeat infringers. If you believe that anything on our Services infringes a copyright
        that you own or control, you may notify 53cr3t’s by contacting copyright@53cr3t.com.
      </T>
      <T variant='body1'>
        Also, please note that if you knowingly misrepresent that any activity or material on our
        Service is infringing, you may be liable to 53cr3t for certain costs and damages.
      </T>

      <T variant='h4'>Payment Information</T>
      <T variant='body1'>
        There are no fees or payments required for use of any aspect of the Services.
      </T>

      <T variant='h4'>Indemnity</T>
      <T variant='body1'>
        Except to the extent prohibited by law, you agree to defend, indemnify, and hold us, our
        licensors, our third party service providers and our officers, employees, licensors, and
        agents (the “53cr3t Entities”) harmless, including costs and attorneys’ fees, from any claim
        or demand made by any third party due to or arising out of (a) your use of the Services, (b)
        your violation of these Terms, (c) your violation of applicable laws or regulations, or (d)
        Your Content. We reserve the right to control the defense of any matter for which you are
        required to indemnify us, and you agree to cooperate with our defense of these claims.
      </T>

      <T variant='h4'>Disclaimers</T>
      <T variant='body1'>
        THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND, EITHER
        EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY,
        FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. 53cr3t, ITS LICENSORS, AND ITS THIRD
        PARTY SERVICE PROVIDERS DO NOT WARRANT THAT THE SERVICES ARE ACCURATE, COMPLETE, RELIABLE,
        CURRENT, OR ERROR FREE. 53cr3t DOES NOT CONTROL, ENDORSE, OR TAKE RESPONSIBILITY FOR ANY
        CONTENT AVAILABLE ON OR LINKED TO THE SERVICES OR THE ACTIONS OF ANY THIRD PARTY OR USER,
        INCLUDING MODERATORS. WHILE 53cr3t ATTEMPTS TO MAKE YOUR ACCESS TO AND USE OF OUR SERVICES
        SAFE, WE DO NOT REPRESENT OR WARRANT THAT OUR SERVICES OR SERVERS ARE FREE OF VIRUSES OR
        OTHER HARMFUL COMPONENTS.
      </T>

      <T variant='h4'>Limitation of Liability</T>
      <T variant='body1'>
        IN NO EVENT AND UNDER NO THEORY OF LIABILITY, INCLUDING CONTRACT, TORT, NEGLIGENCE, STRICT
        LIABILITY, WARRANTY, OR OTHERWISE, WILL THE 53cr3t ENTITIES BE LIABLE TO YOU FOR ANY
        INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, OR LOST
        PROFITS ARISING FROM OR RELATING TO THESE TERMS OR THE SERVICES, INCLUDING THOSE ARISING
        FROM OR RELATING TO CONTENT MADE AVAILABLE ON THE SERVICES THAT IS ALLEGED TO BE DEFAMATORY,
        OFFENSIVE, OR ILLEGAL. ACCESS TO, AND USE OF, THE SERVICES IS AT YOUR OWN DISCRETION AND
        RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR DEVICE OR COMPUTER SYSTEM,
        OR LOSS OF DATA RESULTING THEREFROM. IN NO EVENT WILL THE AGGREGATE LIABILITY OF THE 53cr3t
        ENTITIES EXCEED THE GREATER OF ONE HUNDRED U.S. DOLLARS ($100) OR ANY AMOUNT YOU PAID 53cr3t
        IN THE PREVIOUS SIX MONTHS FOR THE SERVICES GIVING RISE TO THE CLAIM. THE LIMITATIONS OF
        THIS SECTION WILL APPLY TO ANY THEORY OF LIABILITY, INCLUDING THOSE BASED ON WARRANTY,
        CONTRACT, STATUTE, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, AND EVEN IF THE 53cr3t ENTITIES
        HAVE BEEN ADVISED OF THE POSSIBILITY OF ANY SUCH DAMAGE, AND EVEN IF ANY REMEDY SET FORTH
        HEREIN IS FOUND TO HAVE FAILED ITS ESSENTIAL PURPOSE. THE FOREGOING LIMITATION OF LIABILITY
        WILL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION.
      </T>

      <T variant='h4'>Governing Law and Venue</T>
      <T variant='body1'>
        We want you to enjoy 53cr3t, so if you have an issue or dispute, you agree to raise it and
        try to resolve it with us informally. You can contact us with feedback and concerns here or
        by emailing us at contact@53cr3t.com.
      </T>
      <T variant='body1'>
        Except for the government entities listed below: any claims arising out of or relating to
        these Terms or the Services will be governed by the laws of Colorado, other than its
        conflict of laws rules; all disputes related to these Terms or the Services will be brought
        solely in the federal or state courts located in Colorado; and you consent to personal
        jurisdiction in these courts.
      </T>
      <T variant='h6'>Government Entities</T>
      <T variant='body1'>
        If you are a U.S. city, county, or state government entity, then this Section does not apply
        to you.
      </T>
      <T variant='body1'>
        If you are a U.S. federal government entity: any claims arising out of or relating to these
        Terms or the Services will be governed by the laws of the United States of America without
        reference to conflict of laws. To the extent permitted by federal law, the laws of Colorado
        (other than its conflict of law rules) will apply in the absence of applicable federal law.
        All disputes related to these Terms or the Services will be brought solely in the federal or
        state courts located in Colorado.
      </T>

      <T variant='h4'>Changes to these Terms</T>
      <T variant='body1'>
        We may make changes to these Terms, without notice. By continuing to access or use the
        Services on or after the Effective Date of the revised Terms, you agree to be bound by the
        revised Terms. If you do not agree to the revised Terms, you must stop accessing and using
        our Services before the changes become effective.
      </T>

      <T variant='h4'>Termination</T>
      <T variant='body1'>
        You may terminate these Terms at any time and for any reason by deleting your Account and
        discontinuing your use of all Services. If you stop using the Services without deactivating
        your Accounts, your Accounts may be deactivated due to prolonged inactivity.
      </T>
      <T variant='body1'>
        We may suspend or terminate your Accounts, or ability to access or use the Services at any
        time for any or no reason, including for a violation of these Terms or our Content Policy.
      </T>
      <T variant='body1'>
        The following sections will survive any termination of these Terms or of your Accounts:
        (Your Content), (Things You Cannot Do), (Indemnity), (Disclaimers), (Limitation of
        Liability), (Governing Law and Venue), (Termination), and (Miscellaneous).
      </T>

      <T variant='h4'>Miscellaneous</T>
      <T variant='body1'>
        These Terms constitute the entire agreement between you and us regarding your access to and
        use of the Services. Our failure to exercise or enforce any right or provision of these
        Terms will not operate as a waiver of such right or provision. If any provision of these
        Terms is, for any reason, held to be illegal, invalid or unenforceable, the rest of the
        Terms will remain in effect. You may not assign or transfer any of your rights or
        obligations under these Terms without our consent. We may freely assign these Terms.
      </T>
    </SharedStyles.CenteredContent>
  </PageContainer>
);
