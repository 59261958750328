import * as Constants from '../../constants';
import * as SharedStyles from '../Utils/SharedStyles';
import GetStarted from '../GetStarted';
import PageContainer from '../Utils/PageContainer';
import React from 'react';
import styled from '@emotion/styled';
import Typography from '@material-ui/core/Typography';

const GetStartedContainer = styled.div`
  border-top: 1px solid ${(props) => props.theme.palette.grey['700']};
  margin: 6rem -2rem 0 -2rem;
  padding: 2rem 2rem 100vh 2rem;
  text-align: center;

  @media (max-width: ${Constants.MEDIA_QUERY.MD}px) {
    margin: 3rem -1.5rem 0 -1.5rem;
    padding: 1.5rem 1.5rem 100vh 1.5rem;
  }

  @media (max-width: ${Constants.MEDIA_QUERY.SM}px) {
    margin: 2rem -1rem 0 -1rem;
    padding: 1rem 1rem 100vh 1rem;
  }
`;

export default () => (
  <PageContainer>
    <SharedStyles.SpacedCenteredContainer>
      <Typography variant='h4'>That's a NOPE!</Typography>
      <Typography variant='h6'>If the chat ever existed, it doesn't any more.</Typography>
    </SharedStyles.SpacedCenteredContainer>
    <GetStartedContainer>
      <GetStarted />
    </GetStartedContainer>
  </PageContainer>
);
