import * as Constants from '../../constants';
import styled from '@emotion/styled';

export const CenteredContent = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 60%;

  @media (max-width: ${Constants.MEDIA_QUERY.MD}px) {
    width: 80%;
    grid-column-gap: 1.5rem;
    grid-row-gap: 2rem;
  }

  @media (max-width: ${Constants.MEDIA_QUERY.SM}px) {
    width: 90%;
  }
`;

export const Link = styled.a`
  color: ${(props) => props.theme.palette.primary['100']};
`;

export const SpacedCenteredContainer = styled(CenteredContent)`
  margin-top: 6rem;
  text-align: center;

  @media (max-width: ${Constants.MEDIA_QUERY.MD}px) {
    margin-top: 3rem;
  }

  @media (max-width: ${Constants.MEDIA_QUERY.SM}px) {
    margin-top: 1rem;
  }
`;
