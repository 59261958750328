import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';
import { LockContext } from './LockProvider';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockStatusDialog from './LockStatusDialog';
import React from 'react';
import styled from '@emotion/styled';
import Typography from '@material-ui/core/Typography';

const Container = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary['700']};
  border-top: 1px solid ${(props) => props.theme.palette.secondary['700']};
  padding: 1rem;

  :hover {
    background: ${(props) => props.theme.palette.secondary['700']};
    cursor: pointer;

    .editor {
      color: ${(props) => props.theme.palette.secondary['400']};
      display: block;

      :hover {
        color: ${(props) => props.theme.palette.secondary['100']};
      }
    }
  }
`;

const RowsContainer = styled.div`
  margin: 0 auto;
  width: 8rem;
`;

const Row = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 3rem auto;
  justify-items: self-start;
`;

const EditIndicatorContainer = styled.div`
  display: none;
  float: right;
`;

export default () => {
  const lockContext = React.useContext(LockContext);
  const [showEditor, setShowEditor] = React.useState(false);
  const { readEncrypted, saveEncrypted } = lockContext;

  const onCloseEditor = () => setShowEditor(false);
  const onOpenEditor = () => setShowEditor(true);

  const renderRow = (isLocked, text) => {
    const Icon = isLocked ? LockIcon : LockOpenIcon;
    const title = isLocked
      ? 'Encryption is enabled for this operation'
      : 'Encryption is NOT enabled for this operation';
    return (
      <Row>
        <Icon title={title} />{' '}
        <Typography variant='h6' title={title}>
          {text}
        </Typography>
      </Row>
    );
  };

  return (
    <React.Fragment>
      {showEditor && <LockStatusDialog onClose={onCloseEditor} />}
      <Container
        onClick={onOpenEditor}
        title='Click to change your encryption settings for this chat'
      >
        <EditIndicatorContainer className='editor'>
          <EditIcon />
        </EditIndicatorContainer>
        <RowsContainer>
          {renderRow(readEncrypted, 'Reading')}
          {renderRow(saveEncrypted, 'Writing')}
        </RowsContainer>
      </Container>
    </React.Fragment>
  );
};
