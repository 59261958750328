import React from 'react';

export const LockContext = React.createContext({});

export const LockProvider = ({ children }) => {
  const [passPhrase, setPassphrase] = React.useState('');
  const [readEncrypted, setReadEncrypted] = React.useState(false);
  const [saveEncrypted, setSaveEncrypted] = React.useState(false);

  return (
    <LockContext.Provider
      value={{
        passPhrase,
        readEncrypted,
        saveEncrypted,
        setPassphrase,
        setSaveEncrypted,
        setReadEncrypted,
      }}
    >
      {children}
    </LockContext.Provider>
  );
};
