import firebase from 'firebase/app';
import 'firebase/firestore';

//  This one doesn't wait around for a response, and the job removes the entry once done.
export default (chatId, messageId, userId) => {
  try {
    const db = firebase.firestore();

    //  Put the request into the database
    db.collection('deleteChatMessageRequest').add({
      chatId,
      createdOn: firebase.firestore.FieldValue.serverTimestamp(),
      messageId,
      userId,
    });
  } catch (e) {}
};
