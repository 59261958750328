import * as Constants from '../../constants';
import * as effects from '../../hooks/effects';
import * as SharedStyles from '../Utils/SharedStyles';
import * as types from '../../types';
import Entry from './Entry';
import Loading from '../Utils/Loading';
import Messages from './Messages';
import NotFound from './NotFound';
import PageContainer from '../Utils/PageContainer';
import People from './People';
import { LockProvider } from './LockProvider';
import React from 'react';
import styled from '@emotion/styled';

const RoomContainerWithoutPeople = styled.div`
  align-items: flex-start;
  display: grid;
  grid-template-columns: auto 3rem;
`;

const RoomContainer = styled(RoomContainerWithoutPeople)`
  grid-template-columns: auto ${Constants.SIZES.PEOPLE_WIDTH.LG}px;

  @media (max-width: ${Constants.MEDIA_QUERY.MD}px) {
    grid-template-columns: auto ${Constants.SIZES.PEOPLE_WIDTH.MD}px;
  }

  @media (max-width: ${Constants.MEDIA_QUERY.SM}px) {
    grid-template-columns: auto ${Constants.SIZES.PEOPLE_WIDTH.SM}px;
  }
`;

const ChatAreaContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;

  //  need the first one in case the browser doesn't support variables
  height: calc(100vh - ${Constants.SIZES.HEADER_HEIGHT.LG}px);
  height: calc(var(--vh, 1vh) * 100 - ${Constants.SIZES.HEADER_HEIGHT.LG}px);

  @media (max-width: ${Constants.MEDIA_QUERY.MD}px) {
    height: calc(100vh - ${Constants.SIZES.HEADER_HEIGHT.MD}px);
    height: calc(var(--vh, 1vh) * 100 - ${Constants.SIZES.HEADER_HEIGHT.MD}px);
  }

  @media (max-width: ${Constants.MEDIA_QUERY.SM}px) {
    height: calc(100vh - ${Constants.SIZES.HEADER_HEIGHT.SM}px);
    height: calc(var(--vh, 1vh) * 100 - ${Constants.SIZES.HEADER_HEIGHT.SM}px);
  }
`;

const Room = ({ match }) => {
  //  Need to maintain message state in this component so that when they open/close the
  //  people container, it doesn't forget the message that's been entered (since this
  //  toggle changes the outer component, and thus causes a re-render).
  const { chatId, userId } = match.params;
  const { chatData, chatFound, chatLoading } = effects.useChatRoom(chatId);
  const [showPeople, setShowPeople] = React.useState(true);
  const [message, setMessage] = React.useState('');
  const { userFound, userLoading, username } = effects.useUser(userId);

  //  Part of the hack to get optimistic rendering to work
  const setForcedRender = React.useState(null)[1];

  const renderLoading = () => (
    <PageContainer>
      <SharedStyles.SpacedCenteredContainer>
        <Loading />
      </SharedStyles.SpacedCenteredContainer>
    </PageContainer>
  );

  if (chatLoading || userLoading) {
    return renderLoading();
  } else if (!chatFound) {
    return <NotFound />;
  }

  const Container = showPeople ? RoomContainer : RoomContainerWithoutPeople;

  const onMessageAdded = (message) => {
    //  Manually add a message optimistically.
    if (chatData) {
      const now = new Date().getTime();
      const msg = {
        createdBy: username,
        createdOn: { seconds: Math.floor(now / 1000), nanoseconds: now },
        id: `${now}`,
        message,
        optimistic: true,
      };
      if (chatData.messages) {
        chatData.messages.push(msg);
      } else {
        chatData.messages = [msg];
      }
      setForcedRender(now);
    }
  };

  const onMessageAddFailure = () => {
    //  Pop each optimistic entry off the end.
    for (let i = chatData.messages.length - 1; i >= 0; i--) {
      if (chatData.messages[i].optimistic) {
        chatData.messages.pop();
      } else {
        break;
      }
    }
    setForcedRender(new Date().getTime());
  };

  return (
    <LockProvider>
      <Container>
        <ChatAreaContainer>
          <Messages
            chatData={chatData}
            chatId={chatId}
            userId={userId}
            username={userFound ? username : ''}
          />
          <Entry
            chatId={chatId}
            message={message}
            onMessageAdded={onMessageAdded}
            onMessageAddFailure={onMessageAddFailure}
            setMessage={setMessage}
            userId={userId}
          />
        </ChatAreaContainer>
        <People
          chatData={chatData}
          setShowPeople={setShowPeople}
          showPeople={showPeople}
          username={username}
        />
      </Container>
    </LockProvider>
  );
};

Room.propTypes = {
  match: types.ChatRoomUrlParams.isRequired,
};

export default Room;
