import * as Constants from '../../constants';
import * as firebase from '../../firebase';
import * as types from '../../types';
import EntryHelp from './EntryHelp';
import HelpIcon from '@material-ui/icons/Help';
import MaterialTextField from '../Utils/MaterialTextField';
import MuiAlert from '@material-ui/lab/Alert';
import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import styled from '@emotion/styled';
import { withStyles } from '@material-ui/core/styles';
import { LockContext } from './LockProvider';

const EntryContainer = styled.div`
  margin: 0.5rem 2rem;
  max-height: 8rem;
  min-height: 2rem;

  @media (max-width: ${Constants.MEDIA_QUERY.MD}px) {
    margin: 0.5rem 1.5rem;
  }

  @media (max-width: ${Constants.MEDIA_QUERY.SM}px) {
    margin: 0.5rem 1rem;
  }
`;

const HelpIndicator = styled.div`
  float: right;
  right: 0.5rem;
  opacity: 0.5;
  position: relative;
  text-align: right;
  top: 2.7rem;
  width: 2rem;
  z-index: 100;

  :hover {
    cursor: pointer;
  }
`;

const StyledMaterialTextField = withStyles(() => ({
  root: {
    '& textarea': {
      marginRight: '1.5rem',
    },
  },
}))(MaterialTextField);

const StyledSnackbar = withStyles((theme) => ({
  root: {
    bottom: '0.5rem',
    left: `calc(50% - ${Constants.SIZES.PEOPLE_WIDTH.LG / 2}px)`,

    [`@media (max-width: ${Constants.MEDIA_QUERY.MD}px)`]: {
      left: `calc(50% - ${Constants.SIZES.PEOPLE_WIDTH.MD / 2}px)`,
    },

    [`@media (max-width: ${Constants.MEDIA_QUERY.SM}px)`]: {
      left: '1rem',
    },
  },
}))(Snackbar);

const Entry = ({ chatId, message, onMessageAdded, onMessageAddFailure, setMessage, userId }) => {
  const [isInError, setIsInError] = React.useState(false);
  const [showHelp, setShowHelp] = React.useState(false);
  const entryElement = React.useRef(null);

  const lockContext = React.useContext(LockContext);
  const { passPhrase, saveEncrypted } = lockContext;

  const onHelpClick = () => setShowHelp(true);

  const closeError = () => {
    setIsInError(false);
    entryElement.current.querySelector('textarea').focus();
  };

  const renderErrorMessage = () => (
    <StyledSnackbar open={isInError} autoHideDuration={5000} onClose={closeError}>
      <MuiAlert variant='filled' onClose={closeError} severity='error'>
        Sorry, something went horribly wrong and we were not able to send your message.
      </MuiAlert>
    </StyledSnackbar>
  );

  const onCreateMessage = async () => {
    const trimmed = message.trim();
    if (trimmed !== '') {
      setMessage('');
      entryElement.current.querySelector('textarea').focus();

      // Optimistically add the new message, then go perform the "real" update
      onMessageAdded(trimmed);
      const result = await firebase.createChatMessageRequest(
        chatId,
        trimmed,
        userId,
        saveEncrypted && passPhrase
      );

      if (!result.success) {
        onMessageAddFailure();
        setIsInError(true);
      }
    }
  };

  return (
    <EntryContainer ref={entryElement}>
      <EntryHelp onClose={() => setShowHelp(false)} open={showHelp} />
      <HelpIndicator onClick={onHelpClick}>
        <HelpIcon />
      </HelpIndicator>
      {renderErrorMessage()}
      <StyledMaterialTextField
        disabled={isInError}
        multiline={true}
        onChange={(event) => setMessage(event.target.value)}
        onSave={onCreateMessage}
        rows={1}
        rowsMax={5}
        placeholder='Send a message...'
        value={message}
        variant='outlined'
      />
    </EntryContainer>
  );
};

Entry.propTypes = {
  chatId: types.string.isRequired,
  message: types.string.isRequired,
  onMessageAdded: types.func.isRequired,
  onMessageAddFailure: types.func.isRequired,
  setMessage: types.func.isRequired,
  userId: types.string.isRequired,
};

export default Entry;
