import * as Constants from '../../constants';
import * as types from '../../types';
import AddDialog from './AddDialog';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import LockStatus from './LockStatus';
import React from 'react';
import styled from '@emotion/styled';
import Timer from './Timer';
import Typography from '@material-ui/core/Typography';

const AddPeopleContainer = styled.div`
  margin-top: 1rem;
  text-align: center;
`;

const Count = styled.div`
  justify-self: flex-end;
`;

const Name = styled.div``;

const PeopleContainer = styled.div`
  background: ${(props) => props.theme.palette.secondary['800']};
  display: grid;
  grid-template-rows: auto 1fr;

  //  need the first one in case the browser doesn't support variables
  height: calc(100vh - ${Constants.SIZES.HEADER_HEIGHT.LG}px);
  height: calc(var(--vh, 1vh) * 100 - ${Constants.SIZES.HEADER_HEIGHT.LG}px);

  @media (max-width: ${Constants.MEDIA_QUERY.MD}px) {
    height: calc(100vh - ${Constants.SIZES.HEADER_HEIGHT.MD}px);
    height: calc(var(--vh, 1vh) * 100 - ${Constants.SIZES.HEADER_HEIGHT.MD}px);
  }

  @media (max-width: ${Constants.MEDIA_QUERY.SM}px) {
    height: calc(100vh - ${Constants.SIZES.HEADER_HEIGHT.SM}px);
    height: calc(var(--vh, 1vh) * 100 - ${Constants.SIZES.HEADER_HEIGHT.SM}px);
  }
`;

const PeopleHeader = styled.div`
  display: grid;
  grid-template-columns: 2rem auto;
  padding: 0.5rem 2.5rem 0.5rem 0.5rem;
  text-align: center;
`;

const PeopleContent = styled.div`
  display: grid;
  grid-template-rows: 1fr auto auto;
  overflow: scroll;
`;

const PeopleList = styled.div`
  overflow: scroll;
`;

const Person = styled.div`
  align-items: flex-start;
  display: grid;
  grid-column-gap: 2rem;
  grid-template-columns: auto 30px;
  padding: 0.25rem 1rem;

  @media (max-width: ${Constants.MEDIA_QUERY.MD}px) {
    grid-column-gap: 1rem;
    padding: 0.1rem 1rem;
  }
`;

const Strong = styled.span`
  font-weight: bolder;
`;

const People = ({ chatData, setShowPeople, showPeople, username }) => {
  const { messages, users } = chatData;
  const sortedUsers = users.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

  const [showAddDialog, setShowAddDialog] = React.useState(false);

  const onCloseAdd = () => setShowAddDialog(false);
  const onShowAdd = () => setShowAddDialog(true);

  const messageCounts = (messages || []).reduce((acc, message) => {
    const key = message.createdBy;
    acc[key] = acc[key] ? acc[key] + 1 : 1;
    return acc;
  }, {});

  const renderOpenCloseButton = () => {
    if (showPeople) {
      return (
        <IconButton
          aria-label='Close'
          onClick={() => setShowPeople(false)}
          size='small'
          title='Close'
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      );
    }
    return (
      <IconButton
        aria-label='Open to show people in this chat'
        onClick={() => setShowPeople(true)}
        title='Open to show people in this chat'
      >
        <KeyboardArrowLeftIcon />
      </IconButton>
    );
  };

  const renderPeople = () => (
    <PeopleContainer>
      <AddDialog inviteId={chatData.inviteId} onClose={onCloseAdd} open={showAddDialog} />
      <PeopleHeader>
        {renderOpenCloseButton()}
        <Typography variant='h6'>People</Typography>
      </PeopleHeader>
      <PeopleContent>
        <PeopleList>
          {sortedUsers.map((user) => (
            <Person key={user}>
              <Name>
                <Typography variant='body1'>
                  {user === username ? <Strong>{user}</Strong> : user}
                </Typography>
              </Name>
              <Count>
                <Typography variant='body1'>{messageCounts[user] || 0}</Typography>
              </Count>
            </Person>
          ))}
        </PeopleList>
        <LockStatus />
        <AddPeopleContainer>
          <Button
            aria-label='Add People'
            color='primary'
            onClick={onShowAdd}
            size='large'
            variant='contained'
          >
            Add People
          </Button>
        </AddPeopleContainer>
        <Timer expiresOn={chatData.expiresOn} />
      </PeopleContent>
    </PeopleContainer>
  );

  const renderClosed = () => <PeopleContainer>{renderOpenCloseButton()} </PeopleContainer>;

  return showPeople ? renderPeople() : renderClosed();
};

People.propTypes = {
  chatData: types.ChatData.isRequired,
  setShowPeople: types.func.isRequired,
  showPeople: types.bool.isRequired,
  username: types.string.isRequired,
};

export default People;
