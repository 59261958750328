import * as types from '../../types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiAlert from '@material-ui/lab/Alert';
import React from 'react';
import styled from '@emotion/styled';
import Typography from '@material-ui/core/Typography';

const Strong = styled.span`
  font-weight: bolder;
  font-size: 120%;
`;

const StyledAlert = styled(MuiAlert)`
  margin-top: 2rem;
`;

const AddDialog = ({ inviteId, onClose, open }) => {
  if (!open) {
    return null;
  }

  return (
    <Dialog fullWidth={true} open={open} onClose={onClose}>
      <DialogContent>
        <Typography variant='h6'>There are two ways to add people to your chat:</Typography>
        <ul>
          <li>
            <Typography variant='body1'>
              Send them this link: <Strong>https://53cr3t.com/i/{inviteId}</Strong>
            </Typography>
          </li>
          <li>
            <Typography variant='body1'>
              or, have them visit the homepage of our site and enter the code{' '}
              <Strong>{inviteId}</Strong> when prompted to join a chat.
            </Typography>
          </li>
        </ul>
        <Typography variant='body1'>
          The above link and code can be reused, each person it's shared with will get their own
          identity in the chat once they click the link or enter the code.
        </Typography>
        <StyledAlert variant='outlined' severity='warning'>
          <Typography variant='body1'>
            Do not copy and share the link from the address bar of your browser, it is specific to
            your user.
          </Typography>
        </StyledAlert>
      </DialogContent>
      <DialogActions>
        <Button aria-label='Got it' onClick={onClose} variant='outlined'>
          Got It!
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddDialog.propTypes = {
  inviteId: types.string.isRequired,
  onClose: types.func.isRequired,
  open: types.bool.isRequired,
};

export default AddDialog;
