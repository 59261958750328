import * as Constants from '../constants';
import * as SharedStyles from './Utils/SharedStyles';
import React from 'react';
import styled from '@emotion/styled';
import Typography from '@material-ui/core/Typography';

const DetailContainer = styled.div`
  background: ${(props) => props.theme.palette.secondary['800']};
  border-top: 1px solid ${(props) => props.theme.palette.secondary['700']};
  margin: 0 -2rem;
  padding: 2rem 2rem 4rem 2rem;

  @media (max-width: ${Constants.MEDIA_QUERY.MD}px) {
    margin: 1.5rem -1.5rem 0 -1.5rem;
    padding: 1.5rem;
  }

  @media (max-width: ${Constants.MEDIA_QUERY.SM}px) {
    margin: 1rem -1rem 0 -1rem;
    padding: 1rem;
  }
`;

const DetailHeader = styled(SharedStyles.CenteredContent)`
  text-align: center;
`;

const DetailContent = styled(SharedStyles.CenteredContent)`
  margin-top: 2rem;
`;

export default () => {
  return (
    <DetailContainer>
      <DetailHeader>
        <Typography variant='h4'>Security Briefing</Typography>
      </DetailHeader>
      <DetailContent>
        <ul>
          <li>
            <Typography variant='body1'>All communication occurs over SSL.</Typography>
          </li>
          <li>
            <Typography variant='body1'>
              All data is encrypted during transist and at rest.
            </Typography>
          </li>
          <li>
            <Typography variant='body1'>
              All data is transient. When you create a chat room, you decide how long the room will
              survive, from 30-minutes to 24-hours. Once that limit is reached, the chat room, all
              messages, and all users in the chat are deleted from the server database.
            </Typography>
          </li>
          <li>
            <Typography variant='body1'>
              Chat rooms locations are obfuscated by using 20-character IDs drawn from 64
              characters. That's about 2x10^36 possible combinations... it's going to be pretty hard
              to guess a chat room ID.
            </Typography>
          </li>
          <li>
            <Typography variant='body1'>
              You can optionally choose to encrypt your messages in the chat room.
            </Typography>
            <ul>
              <li>
                <Typography variant='body1'>AES-256 encryption is used.</Typography>
              </li>
              <li>
                <Typography variant='body1'>
                  The encryption pass phrase is not stored locally on your browser or on the server.
                  So you'll need to communicate the pass phrase to others in your chat using a
                  secondary communication method: telephone, email, SMS, a Cap'n Crunch decoder
                  ring, or whatever method you think will be most secure.
                </Typography>
              </li>
              <li>
                <Typography variant='body1'>
                  The encryption is per-message, not per-room. So you can have encrypted and
                  unencrypted traffic in the same room. You can even have encrypted messages using
                  different pass phrases. So you can finely control who has access to which messages
                  in a chat room.
                </Typography>
              </li>
            </ul>
          </li>
          <li>
            <Typography variant='body1'>No data is stored locally on your browser.</Typography>
          </li>
          <li>
            <Typography variant='body1'>There are no server backups.</Typography>
          </li>
          <li>
            <Typography variant='body1'>There are no server logs.</Typography>
          </li>
          <li>
            <Typography variant='body1'>
              No personally identifiable information (PII) is collected or stored, either on your
              browser or the server.
            </Typography>
          </li>
          <li>
            <Typography variant='body1'>
              There are no logins or accounts. You need to enter a name to enter a room, but this
              can be any text that you want.
            </Typography>
          </li>
          <li>
            <Typography variant='body1'>
              No location information is collected or stored, either in your browser or the server.
            </Typography>
          </li>
          <li>
            <Typography variant='body1'>
              No servers in China, no dependencies or usages of Chinese services.
            </Typography>
          </li>
        </ul>
      </DetailContent>
    </DetailContainer>
  );
};
