import * as firebase from '../../firebase';
import React from 'react';

export default (chatId) => {
  const [chatFound, setChatFound] = React.useState(false);
  const [chatLoading, setChatLoading] = React.useState(true);
  const [chatData, setChatData] = React.useState([]);

  React.useEffect(() => {
    return firebase.listenToChat(chatId, (doc) => {
      setChatLoading(false);
      if (doc.exists) {
        setChatData(doc.data());
        setChatFound(true);
      } else {
        setChatFound(false);
      }
    });
  }, [chatId]);

  return { chatFound, chatLoading, chatData };
};
