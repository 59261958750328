export const LIFESPANS = {
  0: '30 minutes',
  1: '1 hour',
  2: '3 hours',
  3: '6 hours',
  4: '12 hours',
  5: '24 hours',
};

export const MEDIA_QUERY = {
  SM: 600,
  MD: 960,
};

export const SIZES = {
  HEADER_HEIGHT: {
    SM: 27,
    MD: 35,
    LG: 43,
  },
  PEOPLE_WIDTH: {
    SM: 175,
    MD: 200,
    LG: 300,
  },
};
